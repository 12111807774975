<template>
  <div class="steps">
    <slot
      name="steps"
      :goToNext="goToNext"
      :goToConfirm="goToConfirm"
      :goToPrev="goToPrev"
      :addStep="addStep"
    />
    <slot
      name="footer"
      :goToNext="goToNext"
      :goToPrev="goToPrev"
      :steps="getPurchaseSteps"
      :goTo="goTo"
    />
  </div>
</template>

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
  import {
    googleAccountRenault,
    googleAccountDacia,
    googleAccountDanmark,
    googleAccountDanmarkDacia 
  } from '@/config/defaultValues'

  import {isRenault, isDacia, isDanmarkR, isDanmarkD} from '@/helpers/helpers'

  export default {
    props: {
      isConfirmed: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      stepComponents: [],
    }),
    computed: {
      ...mapState('steps', [ 'nfSteps', 'nfActiveStep']),
      ...mapGetters('steps', [ 'getPurchaseSteps']),
      ...mapState('product', [ 'vehicle', 'priceForm']),
      ...mapState('reseller', ['resellerInfo']),
      ...mapGetters('product', [
        'getBusinessType',
        'getFormCategory',
      ]),
      currentIndex() {
        return this.nfActiveStep.index
      },
      availableStepsIndexes() {
        return this.getPurchaseSteps.map(step => step.index)
      },
      shortSteps() {
        return this.nfSteps.map(step => {
          return {
            index: step.index, 
            title: step.title, 
            active: step.active
          }
        })
      },
      locationId: {
         get() {
           return this.$store.state.order.locationId
         },
       },
       location() {
         return this.vehicle.locations ? this.vehicle.locations.data.find(item => item.id === this.locationId) : {}
       },
       dealerName() {
         const name = this.location.name || '';
         const dealer = this.location.dealer || '';
         return `${name}${name && dealer ? ' - ' : ''}${dealer}`.trim() || 'Unknown Dealer';
       },
    },
    mounted() {
      if (!this.isConfirmed) this.setNfActiveStep(this.nfSteps[0]);
      this.changeStepRouter(this.nfSteps[0].index)
      this.$store.commit('global/enableButton');
    },
    beforeDestroy() {
      this.setNfActiveStep(this.nfSteps[0])
    },
    methods: {
      ...mapActions('steps', [ 'setNfActiveStep',]),
      ...mapMutations('order', ['setStatus', ]),
      ...mapMutations('product', ['setLeadID', ]),
      findClosestStepNext(goal) {
        return this.availableStepsIndexes.filter(el => el !== goal).sort((a, b) => a - b).find(el => el > goal)
      },
      findClosestStepPrev(goal) {
        return this.availableStepsIndexes.filter(step => step !== goal).sort((a, b) => b - a).find(e => e < goal)
      },
      addStep(step) {
        this.stepComponents.push(step)
      },
      beforeChangeStep() {
        this.$emit('beforeChangeStep', this.nfActiveStep);
      },
      afterChangeStep() {
        this.$emit('afterChangeStep', this.nfActiveStep);
      },
      goToConfirm() {
        if (this.stepComponents[1].validate())
            this.$store.commit('global/disableButton');
            this.setResolved()
      },
      goToNext() {
        let index = this.findClosestStepNext(this.currentIndex)
        if (this.stepComponents[0].validate())
          if (this.currentIndex < this.nfSteps.length) {
            index === 1 ? this.setFormStepsDataLayer() : null;
            this.setNfActiveStep(this.nfSteps[index - 1])
            this.changeStepRouter(index);
          }
      },
      goToPrev() {
        let index = this.findClosestStepPrev(this.currentIndex)
        if (this.currentIndex > 1) {
          this.changeStepRouter(index);
          this.setNfActiveStep(this.nfSteps[index - 1])
        }
      },
      goTo(step) {
        if ((step.index > 0) && (step.index <= this.nfSteps.length)) {
          this.changeStepRouter(step.index)
          step.index === 1 ? this.setFormStepsDataLayer() : null;

          this.setNfActiveStep(step)
         }
      },
      setResolved() {
        const unconfirmedStatus = this.resellerInfo.settings?.bankId?.active ? 
            this.resellerInfo.settings.bankId.unconfirmed_status : 1;
        
        this.setStatus(unconfirmedStatus);

        if (isDanmarkR() || isDanmarkD() || isRenault() || isDacia())
          this.setFormCompletionsDataLayer();

        this.confirmed(); 
      },
      changeStepRouter(currentStep) {
        const searchHistoryParams = window.location.search.replace('?', '').split('&');

        let newSearch = searchHistoryParams.reduce((url, next, index) => {
            if (!next.includes('step=')) {
              return `${url}${index === 1 ? '&' : ''}${next}`;
            } else if (next.includes('step=')) {
              return `${url}&step=step-${currentStep}`;
            } else {
              return url
            }
          }, '?')

        if (newSearch.indexOf('step=') === -1) {
          newSearch += `&step=step-${currentStep}`
        }

        history.replaceState({}, null, newSearch)
      },
      confirmed() {
        this.$emit('confirmed');
      },
      setFormStepsDataLayer() {
        if (!isDanmarkR() && !isDanmarkD() && !isRenault() && !isDacia()) return;

        let acc = isDanmarkR() ? googleAccountDanmark : 
          isDanmarkD() ? googleAccountDanmarkDacia : 
          isRenault() ? googleAccountRenault : 
          isDacia() ? googleAccountDacia : '';

        // eslint-disable-next-line no-undef
        dataLayer.push({
          brand: this.vehicle.brand,
          googleAccount: acc,
          pageName: this.$route.name,
          businessType: this.getBusinessType,
          pageType: 'form',
          languageCode: document.documentElement.lang,
          countryCode: document.querySelector('html').code,
          formName: 'order',
          formType: 'finance_request',
          formCategory: this.getFormCategory,
          formStepName: 'accessories',
          formStepNumber: 'step-1',
          vehicleCategory: this.vehicle.type,
          vehicleModel: this.vehicle.model,
          vehicleId: this.vehicle.id,
          dealerName: this.resellerInfo.name,
          dealerId: this.resellerInfo.id,
          event: 'formSteps',
        });
      },
      guid(){
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
      },
      async setFormCompletionsDataLayer() {
        if (!isDanmarkR() && !isDanmarkD() && !isRenault() && !isDacia()) return;

        let acc = isDanmarkR() ? googleAccountDanmark : 
          isDanmarkD() ? googleAccountDanmarkDacia : 
          isRenault() ? googleAccountRenault : 
          isDacia() ? googleAccountDacia : '';

        const leadID = this.guid();

        await this.setLeadID(leadID)

        // eslint-disable-next-line no-undef
        dataLayer.push({
          brand: this.vehicle.brand,
          googleAccount: acc,
          pageName: this.$route.name,
          businessType: this.getBusinessType,
          pageType: this.priceForm,
          languageCode: document.documentElement.lang,
          countryCode: document.querySelector('html').code,
          formName: 'order',
          formType: 'finance_request',
          formCategory: this.getFormCategory,
          formStepName: 'complete',
          formStepNumber: 'step-2',
          leadId: leadID,
          marketingOptinStatus: null,
          vehicleCategory: this.vehicle.type,
          vehicleModel: this.vehicle.model,
          vehicleId: this.vehicle.id,
          dealerName: this.dealerName,
          dealerId: this.resellerInfo.id,
          event: 'formValidate',
        });
      }
    },
  }
</script>
